import "../assets/styles/stripe.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Checkout from "../components/Checkout";
import LoaderScreen from "../components/LoaderScreen";
import MainTitle from "../components/MainTitle";
import { getToken } from "../services/ida";
import { getPhoneNumber } from "../services/utils";

export default function Index() {
  const [title, setTitle] = useState("");
  const [pi, setPi] = useState();
  const [orderId, setOrderId] = useState();
  const [token, setToken] = useState();
  const [orderInfo, setOrderInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [notAvailable, setNotAvailable] = useState(false);
  const [phone, phoneFull, replacePhoneCTM] = getPhoneNumber();
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setPi(params.get("pi") ?? "");
    setOrderId(params.get("oid") ?? "");

    if (!params.has("pi") || !params.has("oid")) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!pi || !orderId) {
      return;
    }

    getToken(pi, orderId)
      .then((data) => {
        setToken(data.paymentClientSecret ?? "");
        setOrderInfo({ ...data });
      })
      .catch((e) => {
        console.log(e);
        setToken("");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pi, orderId]);

  useEffect(() => {
    let title = t("payment-title");

    if (pi === "" || orderId === "" || token === "") {
      title = t("payment-title-no-available");
      setNotAvailable(true);
    }

    setTitle(title);
  }, [pi, orderId, token]);

  useEffect(() => {
    document.title = `${title} - Fenix`;
  }, [title]);

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      <MainTitle title={title} />
      {token && (
        <Checkout
          clientSecret={token}
          orderInfo={orderInfo}
          updateTitle={(t) => setTitle(t)}
        />
      )}
      {notAvailable && (
        <div className="text-white mb-10 max-w-full sm:max-w-[500px]">
          <div className="mb-5">{t("payment-title-no-available-desc")}</div>
          {t("payment-title-no-available-desc-contact")}{" "}
          <a className="underline" href={`tel:${phoneFull}`}>
            {phone}
          </a>
        </div>
      )}
    </>
  );
}
